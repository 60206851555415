import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/HighlightOff'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CircularProgress, Slide, SlideProps } from '@material-ui/core'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { PrimaryButton } from '../../components/Button'
import "./alert.css";

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      borderRadius: '10px',
      padding: '0px 20px'
    },
    titleAlert: {
      color: theme.palette.secondary.dark,
      whiteSpace: 'pre-line'
      // fontWeight: 700,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
    cancelButton: {
      marginRight: '31px'
    },
    dialogContent: {
      marginBottom: 20,
      textAlign: 'center',
      fontSize: '14px',
      color: theme.palette.secondary.main
    },
    promoForm: {
      flexDirection: 'row',
      border: '1px solid #F1F1F1',
      padding: 15,
      marginBottom: 20
    },
    divider: {
      marginBottom: 20
    },
    dialogContentReactivateUser: {
      marginBottom: 20,
      textAlign: "justify",
      fontSize: "14px",
    },
    titleAlertReactivateUser: {
      color: "#575756",
      whiteSpace: "pre-line",
      // fontWeight: 700,
    },
  }
})

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction='up' timeout={10000} ref={ref} {...props} />
))
interface AlertProps {
  isOpen: boolean
  onClose?: any
  headerText?: string
  bodyText?: any
  confirmationButtonText?: string
  onCancelClick?: Function
  onConfirmClick?: any
  showBtnClose?: boolean
  setOpen?: any
  cancelButtonText?: string
  customFunction?: any
  additionalButtons?: any
  isLoading?: boolean
  children?: any
  isSecondStyle?: boolean
}
const AlertConfirmComponent = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
  confirmationButtonText,
  onConfirmClick,
  onCancelClick,
  showBtnClose,
  setOpen,
  cancelButtonText,
  customFunction,
  additionalButtons,
  isLoading,
  children,
  isSecondStyle
}: AlertProps) => {
  const classes = useStyles()

  useEffect(() => {
    if (!confirmationButtonText && !cancelButtonText) {
      setTimeout(() => {
        if (typeof customFunction === 'function') {
          customFunction()
        } else {
          setOpen(false)
        }
      }, 1500)
    }
  }, [])

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        maxWidth='xs'
        open={isOpen}
        classes={{ paper: classes.paper }}
      // PaperProps={{classes: {rounded: "border-radius: 10px;"}}}
      // PaperComponent={'div'}
      >
        <DialogTitle>
          <Typography>{headerText}</Typography>
          {showBtnClose && (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={
            isSecondStyle === undefined
              ? classes.dialogContent
              : classes.dialogContentReactivateUser
          }>
            <span className={
                isSecondStyle === undefined
                  ? classes.titleAlert
                  : classes.titleAlertReactivateUser
              }>{bodyText}</span>
          </DialogContentText>
          {additionalButtons}
          {children}
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              {!!cancelButtonText && (
                <PrimaryButton
                  props={{
                    onClick: () => onCancelClick ? onCancelClick() : onClose(),
                    className: classes.cancelButton
                  }}
                  fullWidth={false}
                  title={cancelButtonText || 'No'}
                />
              )}
              {!!confirmationButtonText && (
                <PrimaryButton
                  props={{
                    onClick: () => onConfirmClick(),
                  }}
                  fullWidth={false}
                  title={isLoading ? (
                    <CircularProgress size={25} color='secondary' />
                  ) : (
                    confirmationButtonText || 'Sí'
                  )}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const AlertConfirm = (props: AlertProps) => {
  return (
    <CustomThemeProvider>
      <AlertConfirmComponent {...props} />
    </CustomThemeProvider>
  )
}

export default AlertConfirm
