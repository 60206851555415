import {
  Avatar,
  Badge,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  RadioGroup,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Search from '@material-ui/icons/Search'
import Close from '@material-ui/icons/Close'
import Check from '@material-ui/icons/Check'
import Business from '@material-ui/icons/Business'
import NestedMenuItem from 'material-ui-nested-menu-item'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState
} from 'react'
import StyledMenu from '../../components/StyledMenu'
import { generateTokens } from '../../actions/auth.action'
import { getEnterprise, getAllEnterpriseByApplication } from '../../actions/enterprise.action'
import {
  parseJwt,
  toPascalCase,
  findFinalOfWord,
  makeId,
  isTokenExpired,
  toPascalCaseMoreWorks
} from '../../utils/utils'
import '../../index.css'

import {
  clearTokenResponse,
  generateTokensErrors
} from '../../store/auth.actionCreators'
import {
  menuReducer as reducer,
  initialState
} from '../../reducers/menu.reducer'
import useAsync from '../../hooks/useAsync'
import FullLoader from '../../components/Loader/FullLoader.component'
import useConfirmationDialog from '../../hooks/useAlertConfirm'
import { DrawMenu } from '../../components/DrawMenu'
import { getMenuItems } from '../../actions/menu.action'
import { getUserByName } from '../../actions/user.action'
import { Enterprise } from '../../models/EnterpriseModels'
import { User, UserDetails, UserInfo } from '../../models/UserModels'
import { USER_MENU } from '../../const/constants'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { PrimaryButton } from '../../components/Button/PrimaryButton'
import { SearchInput } from '../../components/Inputs/SearchInput'
import { CustomModal } from '../../components/Modal/CustomModal'
import { UserProfile } from './UserProfile'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import { withStyles, createStyles } from '@material-ui/styles';

interface UserMenuProps {
  appName: string
  userInfo?: UserInfo
  setTokens: Dispatch<SetStateAction<TokenProps>>
  tokens: TokenProps,
  functions: Array<any>
  viewLoader: boolean
}

interface CompanyProps {
  id: number
  documentType: string
  documentNumber: string
}

const UserMenuComponent = ({
  appName,
  userInfo,
  setTokens,
  tokens,
  functions,
  viewLoader
}: UserMenuProps) => {
  const userInformation = userInfo || {
    user: {
      enterprise: '',
      email: '',
      name: '',
      role: [''],
      profilePicture: ''
    }
  }
  // #region  states declaration
  const theme = useTheme()

  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { enterprises, tokenErrors } = state
  const [anchorEl, setAnchorEl] = React.useState<boolean | null>(null)
  const [, setAnchorElPop] = React.useState<boolean | null>(null)
  const openMenu = Boolean(anchorEl)
  const [enteredFilter, setEnteredFilter] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [CurrentCompany, setCurrentCompany] = useState<CompanyProps>()
  const [CurrentCustomer, setCurrentCustomer] = useState<CompanyProps>()
  const [msgCustomer, setMsgCustomer] = useState('')
  const [enterpriseLoader, setEnterpriseLoader] = useState(true)
  const [user, setUser] = useState<User>(userInformation.user)
  const [userList, setUserList] = useState([])
  const [applicationList, setApplicationList] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [userInfoComplete, setUserInfoComplete] = useState<UserInfoComplete>({
    userId: '',
    names: '',
    surNames: '',
    documentType: '',
    documentNumber: '',
    email: '',
    phone: '0',
    userName: ''
  })
  const [, setUserDetails] = useState<UserDetails>({
    token: {
      customerId: 0,
      nameId: 0
    },
    license: false
  })

  const styleProps = {
    '&:hover': {
      backgroundColor: "#0000000D",
      color: theme.palette.common.white
    },
  }
  const [enterpriseDetails, setEnterpriseDetails] = useState<Enterprise[]>([])
  const { result, error, execute, loading } = useAsync({
    asyncFunction: generateTokens
  })
  const {
    result: menuResult,
    error: menuError,
    execute: executeMenu,
    loading: loadingMenu
  } = useAsync({
    asyncFunction: getMenuItems
  })

  const {
    result: userResult,
    error: userError,
    execute: executeUser,
    loading: loadingUser
  } = useAsync({
    asyncFunction: getUserByName
  })
  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    bodyText: '¿Está seguro de cerrar sesión?',
    cancelButtonText: 'No',
    confirmationButtonText: 'Si',
    onConfirmClick: () => closeSession(),
    onCancelClick: () => {
      setAnchorEl(null)
      onClose()
    }
  })

  const { Dialog: ErrorDialog } = useConfirmationDialog({
    bodyText: menuError,
    confirmationButtonText: 'Aceptar'
  })

  const { Dialog: CustomerDialog, onOpen: customerOpen } =
    useConfirmationDialog({
      bodyText: msgCustomer,
      confirmationButtonText: 'Aceptar'
    })

  const { Dialog: UserDialog } = useConfirmationDialog({
    bodyText: userError,
    confirmationButtonText: 'Aceptar'
  })

  const enteredCustomerFilter = ''
  const { token } = tokens.result
  // #endregion

  // #region   Effects

  useEffect(() => {
    if (result) {
      if (result.statusCode === 200) {
        setTokens((oldTokens: TokenProps) => ({
          ...oldTokens,
          result: {
            ...oldTokens.result,
            token: result.message.token,
            refreshToken: result.message.refreshToken
          }
        }))
        //   setEnterpriseDetails(result.message.enterpriseList)
      } else if (error) {
        // setMsgCustomer(result.message)
        // customerOpen()
        console.log(error)
      }
    }
  }, [result, error, setTokens])

  /**
   * get user information from token and get enterprises based on that information
   */
  useEffect(() => {
    if (token) {
      const userResult = parseJwt(token)
      const keyRole = Object.keys(userResult).find((usr) =>
        usr.includes('ROLE')
      )
      const roles = userResult[keyRole ? keyRole : 'IBUHOO_ROLE']
      let profilePicture = ''
      if (user.profilePicture) {
        profilePicture = user.profilePicture
      } else {
        profilePicture = `${userResult.given_name.charAt(
          0
        )}${userResult.family_name.charAt(0)}`
      }

      setUser({
        ...user,
        email: userResult.email,
        name: `${userResult.given_name} ${userResult.family_name}`,
        role: Array.isArray(roles) ? roles : [roles],
        profilePicture
      })
      const tokenExecute = `Bearer ${token}`
      if (!isTokenExpired(tokenExecute)) {
        executeUser({
          token: tokenExecute,
          Username: userResult.unique_name
        })
        executeMenu({
          token: tokenExecute,
          appName,
          idmenulocation: parseInt(USER_MENU, 10)
        })

        setSelectedCustomer(toPascalCase(userResult.customer_name))
        setCurrentCompany({
          id: parseInt(userResult.customer_id, 10),
          documentType: userResult.customer_type,
          documentNumber: userResult.customer_value
        })
        setCurrentCustomer({
          id: parseInt(userResult.customer_id, 10),
          documentType: userResult.customer_type,
          documentNumber: userResult.customer_value
        })
        setUserDetails({
          token: {
            customerId: userResult.customer_id,
            nameId: userResult.nameid
          },
          license: false
        })

        if (!enterprises.length) {
          if (userResult !== undefined) {
            if (userResult.type_application === "Transversal") {
              getEnterprise(userResult.nameid, appName, tokens.result.token, dispatch)
            } else {
              const privilege = "SUITE_FULL_ACCESS";
              const clavePrivileges = `${userResult.APPS}_PRIVILEGE`;
              if (userResult[clavePrivileges].includes(privilege)) {
                getAllEnterpriseByApplication(appName, true, tokens.result.token, dispatch);
              } else {
                getEnterprise(userResult.nameid, appName, tokens.result.token, dispatch)
              }
            }
          }
        }
      }
    }
  }, [token])

  useEffect(() => {
    if (!!enterprises.length && !enterpriseDetails.length) {
      setEnterpriseDetails(enterprises);
      setEnterpriseLoader(false);
    }    
    if (enterprises.length === 0){
      setEnterpriseLoader(false);      
    }
  }, [enterprises])

  useEffect(() => {
    if (result) {
      result.statusCode === 200
        ? setMsgCustomer(
          'Se ha cambiado correctamente a la empresa solicitada.'
        )
        : setMsgCustomer(
          'No se puede cambiar la empresa, la empresa seleccionada no dispone de licenciamiento para esta aplicación.'
        )

      customerOpen()
      dispatch(clearTokenResponse())
    }
  }, [result])

  useEffect(() => {
    if (tokenErrors.message !== '') {
      setMsgCustomer(tokenErrors.message)
      customerOpen()
      dispatch(generateTokensErrors({ message: '', statusCode: 0 }))
    } else if (tokenErrors.statusCode === 500) {
      // setMsgCustomer('ha ocurrido un error inesperado')
      console.log(tokenErrors)
    }
  }, [tokenErrors])

  useEffect(() => {
    if ((!userList.length || !applicationList.length) && !loadingMenu) {
      // executeMenu({ token, appName, idmenulocation: parseInt(USER_MENU, 10) })
    }
  }, [])

  useEffect(() => {
    if (menuResult) {
      if (menuResult.statusCode === 200) {
        if (menuResult.message.records.length) {
          const menuResponse = menuResult.message.records[0].menuJson
          const response = JSON.parse(menuResponse)
          setUserList(response.user)
          setApplicationList(response.application)
        }
      }
    } else if (menuError) {
      // onErrorOpen()
      console.log(menuError)
    }
  }, [menuResult, menuError])

  useEffect(() => {
    if (userResult) {
      if (userResult.statusCode === 200) {
        if (userResult.message.users.length) {
          const user = userResult.message.users[0]
          setUserInfoComplete({
            userId: user.id,
            names: toPascalCaseMoreWorks(user.names),
            surNames: toPascalCaseMoreWorks(user.surNames),
            documentType: 'Cédula de cuidadania.',
            documentNumber: user.document,
            email: user.email,
            phone: user.phoneNumber ? user.phoneNumber : '0',
            userName: user.userName
          })
        }
      }
    } else if (userError) {
      // userOpen()
      console.log(userError)
    }
  }, [userResult, userError])

  // #endregion

  // #region  handleEvents
  const handleChange = (valueCustomer: CompanyProps) => {
    setCurrentCustomer(valueCustomer)
  }

  const changeCurrentCompany = (valueCustomer?: CompanyProps) => {
    try {
      const currentComparator = valueCustomer ? valueCustomer : CurrentCustomer
      if (currentComparator?.documentType !== CurrentCompany?.documentType ||
        currentComparator?.documentNumber !== CurrentCompany?.documentNumber) {
        const tokenCustomer = token.replace('Bearer ', '')
        const currentCustomerSelected = enterpriseDetails.find(x => x.id === currentComparator?.id);
        const bodyCustomer = {
          token: tokenCustomer,
          Option: 'OPT02',
          EnterpriseSelected: currentComparator?.id,
          CurrentApp: appName,
          EnterpriseTypeId: currentCustomerSelected?.documentType,
          EnterpriseDocumentNumber: currentCustomerSelected?.documentNumber
        }
        setAnchorEl(false)
        setModalOpen(false)
        execute(bodyCustomer, dispatch)
      }
      setAnchorEl(false)
      setModalOpen(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElPop(null)
  }

  const handleModalOpen = () => {
    if (isModalOpen) {
      setModalOpen(false)
    } else {
      setModalOpen(true)
    }
  }

  const handleModalInfoOpen = () => {
    if (isModalInfoOpen) {
      setModalInfoOpen(false)
    } else {
      setModalInfoOpen(true)
    }
  }

  const closeSession = () => {
    if (user.closeSession) {
      user.closeSession()
    } else {
      onClose()
      localStorage.removeItem('jwtToken')
    }
  }

  const handleConfirm = () => {
    onOpen()
  }
  // #endregion

  // #region extra render options
  const getRowData = (data: Enterprise[]) => {
    const filtered = data.filter(
      (num: Enterprise) =>
        num.isLicensed === true &&
        (num.name.toLowerCase().indexOf(enteredCustomerFilter.toLowerCase()) >=
          0 ||
          enteredCustomerFilter === '')
    )
    if (filtered.length <= 0) {
      return (
        <Grid key='no-customers' style={{ margin: 10 }}>
          No hay empresas
        </Grid>
      )
    }
    return filtered
      .sort((a) => ((`${a.documentNumber}` === CurrentCompany?.documentNumber && a.documentType === CurrentCompany.documentType) ? -1 : 1))
      .slice(0, 5)
      .map((row) => {
        const totalName = row.name
        const customerId = row.id
        const customerNumber = `${row.documentNumber}`;
        const customerType = row.documentType;
        return (
          <ListItem key={makeId(10)} role={undefined} dense button
            onClick={() => changeCurrentCompany({
              id: customerId,
              documentNumber: customerNumber,
              documentType: customerType
            })}>
            <Grid container direction='row'
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
              justifyContent='space-between'>
              <Grid item xs={11} lg={11}>
                <Typography variant='subtitle1' color='textPrimary'>
                  {totalName.length > 12 ? (
                    <Tooltip title={totalName}>
                      <Typography variant='subtitle1' color='textPrimary'>
                        {`${findFinalOfWord(totalName, 12, ' ')}...`}
                      </Typography>
                    </Tooltip>
                  ) : (
                    totalName
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1} lg={1}>
                {(customerNumber === CurrentCustomer?.documentNumber && customerType === CurrentCustomer.documentType) ? (
                  <Check style={{ color: theme.paletteUserMenu.Icon.primary.dark }} />
                ) : null}
              </Grid>
            </Grid>
          </ListItem>
        )
      })
  }

  const createListCustomers = () => {
    let customers = (enterpriseDetails.length ? enterpriseDetails : [])
      .sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.name === b.name ? 0 : a.name > b.name ? 1 : -1
      ).filter(
        (temp) =>
          temp.isLicensed === true &&
          temp.name
            .toString()
            .toLowerCase()
            .includes(enteredFilter.toLowerCase())
      );
    if (customers.length > 0) {
      return customers.sort((a) => ((`${a.documentNumber}` === CurrentCompany?.documentNumber && a.documentType === CurrentCompany.documentType) ? -1 : 1))
        .map((item) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div key={makeId(7)}>
              <ListItem
                key={makeId(7)}
                role={undefined}
                dense
                button
                onClick={() => handleChange({
                  id: item.id,
                  documentType: item.documentType,
                  documentNumber: `${item.documentNumber}`
                })}
                style={{ marginTop: '10px', marginBottom: '10px', paddingLeft: "30px", paddingRight: "30px" }}
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                >
                  <Grid item xs={11} lg={11}>
                    {item.name.length >= 30 ? (
                      <Tooltip title={item.name}>
                        <Typography
                          variant='subtitle1'
                          color='textPrimary'
                        >
                          {`${findFinalOfWord(item.name, 30, undefined)}...`}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        variant='subtitle1'
                        color='textPrimary'
                      >
                        {item.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={1} lg={1}>
                    {(`${item.documentNumber}` === CurrentCustomer?.documentNumber && item.documentType === CurrentCustomer.documentType) ? (
                      <Check
                        style={{
                          color: theme.paletteUserMenu.Icon.primary.dark
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </ListItem>
            </div>
          )
        })
    } else {
      return (
        <div>
          <ListItem
            key={makeId(7)}
            role={undefined}
            dense
            button
            disabled={true}
            onClick={() => { }}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
            >
              <Grid item xs={11} lg={11}>
                {"No se encontraron resultados".length >= 30 ? (
                  <Tooltip title={"No se encontraron resultados"}>
                    <Typography
                      variant='subtitle1'
                      color='textPrimary'
                    >
                      {`${findFinalOfWord("No se encontraron resultados", 30, undefined)}...`}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    variant='subtitle1'
                    color='textPrimary'
                  >
                    {"No se encontraron resultados"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </ListItem>
        </div>
      )
    }
  }

  const createViewRoles = () => {
    let rolesComplete = "";
    const indexSubstring = 25;
    user.role.map((rol, index) => {
      if (index == user.role.length - 1) {
        rolesComplete += rol;
      } else {
        rolesComplete += rol + ", ";
      }
    });

    if (rolesComplete.length > indexSubstring) {
      return (
        <Tooltip title={rolesComplete}>
          <Typography align='left' className={classes.underlined}>
            {
              `${findFinalOfWord(rolesComplete, indexSubstring, '')} ...`
            }
          </Typography>
        </Tooltip>
      );
    } else {
      return (
        <Typography align='left' className={classes.underlined}>
          {rolesComplete}
        </Typography>
      );
    }
  }

  const moreCompanies = (customers: Enterprise[]) => {
    if (customers.length) {
      if (customers.filter((x) => x.isLicensed).length > 5) {
        return (
          <List component='nav' aria-label='otrasEmpresas'>
            <ListItem
              key={makeId(7)}
              style={{ width: '100%' }}
              button
              className={classes.rightButton}
              onClick={handleModalOpen}
            >
              <Grid container direction='row' justifyContent='flex-start'>
                <Grid item lg={10} md={10} xs={10}>
                  {' '}
                  <ListItemText
                    primary='Ver más empresas'
                    color={theme.paletteUserMenu.Icon.primary.light}
                    primaryTypographyProps={{
                      variant: 'subtitle2'
                    }}
                    style={{
                      color: theme.palette.primary.light,
                      textAlign: 'center'
                    }}
                  />
                </Grid>
                <Grid item lg={2} md={2} xs={2}>
                  <span
                    className='material-icons'
                    style={{ color: theme.paletteUserMenu.Icon.primary.light }}
                  >
                    navigate_next
                  </span>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        )
      }
      return null
    }
    return null
  }

  const viewListEnterprise = () => {
    return userInformation.user.enterprise ? (
      <div>
        <NestedMenuItem
          key={`customers-nested ${makeId(7)}`}
          parentMenuOpen={!!anchorEl}
          className={classes.root}
          style={{ padding: "8px 16px 8px 30px" }}
          label={
            <div className={classes.labelNestedItemMenu}>
              <ListItemIcon className={classes.spacingIcon}>
                <Business
                  fontSize='small'
                  style={{ color: theme.palette.text.primary }}
                />
              </ListItemIcon>
              <ListItemText
                primary='Empresas'
                primaryTypographyProps={{
                  color: 'textPrimary',
                  variant: 'subtitle1'
                }}
              />
            </div>
          }
        >
          {enterpriseLoader ? (
            <CircularProgress
              size={20}
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginRight: 20,
                marginLeft: 20
              }}
            />
          ) : (
            <div style={{ width: '300px' }}>
              <RadioGroup
                aria-label='CustomTot'
              // value={CurrentCompany?.documentNumber}
              // onChange={(e: any) =>
              //   changeCurrentCompany(parseInt(e.target.value, 10))
              // }
              >
                {getRowData(
                  enterpriseDetails.length ? enterpriseDetails : []
                )}
              </RadioGroup>
              {moreCompanies(enterpriseDetails)}
            </div>
          )}
        </NestedMenuItem>
      </div>
    ) : null
  }
  // #endregion

  return (
    <div>
      {loading || loadingMenu || loadingUser ? (
        <FullLoader
          open={loading || loadingMenu || loadingUser}
          handleClose={() => { }}
          viewLoader={viewLoader}
        />
      ) : (
        <div>
          <Avatar
            className='avatar'
            color='default'
            src={user.profilePicture}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            {user.profilePicture ? (
              user.profilePicture
            ) : (
              <CircularProgress size={20} color='secondary' />
            )}
          </Avatar>
          <StyledMenu
            id='customized-menu'
            key='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            classes={classes}
            onClose={handleClose}
            component='div'
            background='green'
            paddingtop={8}
            paddingbottom={18}
          >
            <div className={classes.contentProfile}>
              <Grid container justifyContent='center'>
                <Grid item container justifyContent='center'>
                  {user.profilePicture ? (
                    <StyledBadge
                      badgeContent={
                        <div className={classes.circularBackground}>
                          <span
                            className='material-icons'
                            style={{
                              fontSize: '25px',
                              color: theme.palette.common.white
                            }}
                          >
                            photo_camera
                          </span>
                        </div>
                      }
                    >
                      <Avatar
                        alt={user.profilePicture}
                        className={classes.profilePicture}
                        src={user.profilePicture}
                      >
                        <AccountCircle
                          style={{ fontSize: 110, color: '#6D6E71' }}
                        />
                      </Avatar>
                    </StyledBadge>
                  ) : (
                    <AccountCircle style={{ fontSize: 50 }} />
                  )}
                </Grid>
                <Grid item container md={12} justifyContent='center'>
                  <Typography
                    color='textSecondary'
                    component='span'
                    variant='subtitle1'
                    style={{
                      fontSize: '19px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '0px 16px'
                    }}
                  >
                    {user.name ? toPascalCaseMoreWorks(user.name) : ''}
                  </Typography>
                </Grid>
                <Grid item container justifyContent='center'>
                  <Typography component='span' variant='h6' color='textPrimary'>
                    {user.email}
                  </Typography>
                </Grid>
                <Grid item container justifyContent='center'>
                  <Grid
                    style={{ marginTop: '2px', marginBottom: '2px' }}
                    container
                    item
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                  >
                    <Typography align='right' variant='h6' color='textPrimary'
                      style={{ marginRight: "5px" }}>
                      Rol(es): {' '}
                    </Typography>
                    {
                      createViewRoles()
                    }
                  </Grid>
                </Grid>
                <Grid item container justifyContent='center'>
                  <Tooltip title={selectedCustomer}>
                    <Typography
                      component='span'
                      variant='h6'
                      color='textPrimary'
                    >
                      {'Empresa: '}
                      {selectedCustomer.length >= 20
                        ? `${findFinalOfWord(selectedCustomer, 20, ' ')}...`
                        : selectedCustomer}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>

            {
              applicationList.length ? <Divider classes={{ root: classes.divider }} /> : <div></div>
            }
            <DrawMenu
              menu={applicationList.length ? applicationList : []}
              haveDivider={false}
              props={styleProps}
              functions={functions}
              isLargePadding={true}
              functionOnClose={handleClose}
            />
            <Divider classes={{ root: classes.divider }} />
            <DrawMenu
              menu={userList.length ? userList : []}
              haveDivider={false}
              props={styleProps}
              functions={[{ name: "handleModalInfoOpen", function: handleModalInfoOpen }, ...functions]}
              isLargePadding={true}
              functionOnClose={handleClose}
            />
            {viewListEnterprise()}
            <MenuItem
              key='close-session'
              onClick={handleConfirm}
              className={classes.root}
            >
              <ListItemIcon className={classes.spacingIcon}>
                <PowerSettingsNew
                  fontSize='small'
                  style={{ color: theme.palette.text.primary }}
                />
              </ListItemIcon>
              <ListItemText
                primary='Cerrar Sesión'
                primaryTypographyProps={{
                  color: 'textPrimary',
                  variant: 'subtitle1'
                }}
              />
            </MenuItem>
          </StyledMenu>
          <CustomModal
            open={isModalOpen}
            onClose={handleModalOpen}
            children={
              <div>
                <Grid
                  container
                  direction='row-reverse'
                  style={{ cursor: 'pointer' }}
                  onClick={handleModalOpen}
                >
                  <Grid item lg={1} md={1} xs={1} style={{ height: '20px' }}>
                    <Close color='disabled' />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{ marginLeft: '8px', height: '37px', paddingRight: '30px' }}
                  alignItems='flex-end'
                >
                  <Grid item lg={1} md={1} xs={1}>
                    <Search color='disabled' style={{ marginTop: '5px' }} />
                  </Grid>
                  <Grid item lg={11} md={11} xs={11}>
                    <SearchInput
                      enteredFilter={enteredFilter}
                      setEnteredFilter={setEnteredFilter}
                    />
                  </Grid>
                </Grid>
                <Divider classes={{ root: classes.divider }} />
                <List key={makeId(7)} className={classes.radioList}>
                  {
                    createListCustomers()
                  }
                </List>
                <Grid
                  container
                  justifyContent='center'
                  style={{
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '40px'
                  }}
                >
                  {' '}
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <PrimaryButton
                      title='Aceptar'
                      fullWidth={true}
                      props={{
                        onClick: () => changeCurrentCompany(undefined)
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            }
          />
          <UserProfile
            isModalOpen={isModalInfoOpen}
            onClose={handleModalInfoOpen}
            setModalOpen={setModalInfoOpen}
            user={user}
            userInfoComplete={userInfoComplete}
          />
          <Dialog />
          <CustomerDialog />
          <ErrorDialog />
          <UserDialog />
        </div>
      )}
    </div>
  )
}

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      top: 75,
      right: 15,
    },
  }),
)(Badge);

const useStyles = makeStyles((theme: Theme) => {
  return {
    popover: {
      pointerEvents: 'none'
    },
    labelNestedItemMenu: {
      display: "flex",
      width: "100%",
      alignItems: "center"
    },
    labelForm: {
      fontSize: '10px',
      marginTop: '15px'
    },
    colorForm: {
      color: '#4F4F4F'
    },
    rightButton: {
      padding: theme.spacing(0, 0, 0, 2)
    },
    nameTypography: {
      color: theme.palette.primary.main,
      font: 'normal normal bold 19px/24px Muli'
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      title: {
        justifyContent: 'left'
      },
      backgroundColor: theme.palette.background.paper,
      borderRadius: `8px`,
      boxShadow: theme.shadows[5],
      opacity: 1
    },
    modalEnterprises: {
      height: '10vh',
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.paper
    },
    radioList: {
      maxHeight: 400,
      overflowY: 'scroll'
    },
    root: {
      '& material-icons': {
        color: theme.palette.info.main
      },
      font: 'normal normal 600 16px/20px Muli',
      '& label.Mui-focused': {
        color: 'green'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green'
      },
      '&:focus': {
        background: "#0000000D",
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      },
      '&:hover': {
        background: "#0000000D",
        color: theme.palette.text.primary
      },
      color: theme.palette.text.primary,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.text.secondary
      },
      paddingLeft: "30px"
    },
    logo: {
      width: '163px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '43px',
      //   backgroundImage: theme.logo.backgroundImage,
      paddingTop: '14px'
      // flex: 1,
    },
    contentProfile: {
      width: '100%',
      paddingBottom: '18px',
      paddingTop: '8px'
    },
    BadgeStyle: {
      '& .MuiBadge-badge': {
        right: 14,
        bottom: 27
      }
    },
    profilePicture: {
      marginBottom: 12,
      height: '94px',
      width: '94px'
    },
    circularBackground: {
      padding: '1px',
      background: theme.paletteUserMenu.Icon.primary.main,
      '-moz-border-radius': '50%',
      '-webkit-border-radius': '50%',
      borderRadius: '50%',
      borderColor: theme.paletteUserMenu.Icon.primary.main,
      borderStyle: 'solid',
      borderWidth: '1px'
    },
    paper: {
      boxShadow: '0px 3px 10px #0000004D',
      opacity: 1,
      marginTop: 8,
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: '340px',
      backgroundColor: theme.palette.background.paper,
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '94%',
        top: '-15px',
        right: 0,
        width: 16,
        height: 15,
        backgroundColor: theme.palette.background.paper,
        borderColor: '#d3d4d5',
        borderStyle: 'solid',
        borderWidth: '1px 1px 0px 0px',
        borderRadius: 3,
        transform: 'translate(-50%, 50%) rotate(316deg)'
      }
    },
    underlined: {
      color: theme.palette.primary.light,
      fontFamily: 'Muli',
      fontSize: '14px',
      fontWeight: 'normal',
      letterSpacing: '0px',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    divider: {
      backgroundColor: theme.palette.info.main,
      marginLeft: '5px',
      marginRight: '5px',
      height: '1px'
    },
    spacingIcon: {
      minWidth: "40px"
    }
  }
})

export const UserMenu = (props: UserMenuProps) => {
  return (
    <CustomThemeProvider>
      <UserMenuComponent {...props} />
    </CustomThemeProvider>
  )
}
