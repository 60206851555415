import React, { useEffect, useState } from 'react'
import { Card, Grid, IconButton, makeStyles } from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import { ColorPicker } from '../../../components/button/ColorPicker'
import { PrimaryButton } from '../../../components/button/PrimaryButton'
import { SelectFileButton } from '../../../components/button/SelectFileButton'
import { CustomTypography } from '../../../components/typography/CustomTypography'
import FullLoader from '../../../components/Loader/FullLoader.component'
import { EditButton } from '../../../components/Button/EditButton'

import useDialog from '../../../hooks/useAlertConfirm'
import useAsync from '../../../hooks/useAsync'

import {
  createPersonalization,
  getPersonalization,
  updatePersonalization
} from '../Services/CompanyServices'
import { valueOrDefault } from '../../../utils/utils'
import { ICreatePersonalizationConfig, jsonColor } from '../types'
import { SnackbarProvider } from 'notistack'

type Colors = {
  buttonColor?: string
  buttonTextColor?: string
}

type logoState = {
  name: string
  blob: string
  path: string
  extension: '.jpg' | '.png' | '.jpeg' | '.svg' | '.gif' | '.ico'
}
export interface CompanyPersonalizationData {
  token: string
  color?: Colors
  personalizationColor?: jsonColor
}

const initialPersonalization: ICreatePersonalizationConfig = {
  principalColor: '#1D71B8',
  secondaryColor: '#fa5a00',
  buttonColor: '#1D71B8',
  buttonTextColor: '#FFFFFF',
  backgroundColor: '#FFFFFE',
  logo: { blob: '', name: '', path: '', extension: '.jpg' },
  backgroundImage: { blob: '', name: '', path: '', extension: '.jpg' }
}

export const CompanyPersonalization = ({
  token,
  color,
  personalizationColor
}: CompanyPersonalizationData) => {
  const classes = useStyles()
  //#region
  const [isEdit, setIsEdit] = React.useState(false)
  const [isNew, setIsNew] = React.useState(false)
  const [loader, setLoader] = useState(false)

  const [logo, setLogo] = useState<logoState>(initialPersonalization.logo)
  const [principalColor, setPrincipalColor] = useState('')
  const [secondaryColor, setSecondaryColor] = useState('')
  const [buttonColor, setButtonColor] = useState('')
  const [buttonTextColor, setButtonTextColor] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('')
  const [backgroundImage, setBackgroundImage] = useState<logoState>(
    initialPersonalization.backgroundImage
  )

  const [fileError, setFileError] = useState('')
  const [fileError2, setFileError2] = useState('')
  const [message, setMessage] = React.useState<string>()

  //#CustomHooks
  const { error, execute, loading, result } = useAsync({
    asyncFunction: isNew ? createPersonalization : updatePersonalization
  })
  const { Dialog, onOpen } = useDialog({
    bodyText: message ? message : 'Personalización creada con éxito',
    cancelButtonText: 'Cerrar'
  })

  //#region

  useEffect(() => {
    if (result) {
      if (result.status === 200) {
        onOpen()
        setMessage(result.data.result.information)
      }
    } else if (error) {
      onOpen()
      setMessage(error.message)
    }
  }, [result, error])

  useEffect(() => {
    getPersonalization(token)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response
          const { result } = data
          const parameters = result.entityDto
          if (parameters === null) {
            setIsNew(true)
            setPrincipalColor(initialPersonalization.principalColor)
            setSecondaryColor(initialPersonalization.secondaryColor)
            setButtonColor(initialPersonalization.buttonColor)
            setButtonTextColor(initialPersonalization.buttonTextColor)
            setBackgroundColor(initialPersonalization.backgroundColor)
          }

          parameters &&
            Object.keys(parameters).forEach((key) => {
              if (key === 'logo')
                setLogo((oldState) => ({
                  ...oldState,
                  blob: parameters[key].blob,
                  name: parameters[key].name,
                  path: parameters[key].path
                }))
              if (key === 'backgroundImage')
                setBackgroundImage((oldState) => ({
                  ...oldState,
                  blob: parameters[key].blob,
                  name: parameters[key].name,
                  path: parameters[key].path
                }))
              if (key === 'backgroundColor')
                setBackgroundColor(
                  valueOrDefault(
                    parameters[key],
                    initialPersonalization.backgroundColor
                  )
                )
              if (key === 'principalColor')
                setPrincipalColor(
                  valueOrDefault(
                    parameters[key],
                    initialPersonalization.principalColor
                  )
                )
              if (key === 'secondaryColor')
                setSecondaryColor(
                  valueOrDefault(
                    parameters[key],
                    initialPersonalization.secondaryColor
                  )
                )

              if (key === 'buttonColor')
                setButtonColor(
                  valueOrDefault(
                    parameters[key],
                    initialPersonalization.buttonColor
                  )
                )

              if (key === 'buttonTextColor')
                setButtonTextColor(
                  valueOrDefault(
                    parameters[key],
                    initialPersonalization.buttonTextColor
                  )
                )
            })
        }
      })
      .catch((error) => console.error(error))
  }, [])

  //#endregion

  const inactivaCampos = () => {
    setIsNew(false)
    setIsEdit(false)
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    setLoader(true)
    savePersonalization()
  }

  const savePersonalization = async () => {
    const processedData: ICreatePersonalizationConfig = {
      logo: logo,
      buttonColor: buttonColor,
      buttonTextColor: buttonTextColor,
      principalColor: principalColor,
      secondaryColor: secondaryColor,
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage
    }
    setLoader(false)
    execute(processedData, token)
    inactivaCampos()
  }

  const deleteFile = (image: string) => {
    switch (image) {
      case 'logo':
        setLogo({
          name: '',
          blob: '',
          extension: '.jpg',
          path: ''
        })
        break

      case 'backgroundImage':
        setBackgroundImage({
          name: '',
          blob: '',
          extension: '.jpg',
          path: ''
        })
        break

      default:
        break
    }
  }

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <form onSubmit={(e) => handleSave(e)}>
        <Grid container spacing={2}>
          <Grid container item lg={10} md={10} sm={12} xs={12} spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <CustomTypography type='subtitle'>{'Logo'}</CustomTypography>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item>
                    <SelectFileButton
                      label='Seleccionar'
                      setFile={setLogo}
                      disabled={!isEdit}
                      acceptedExtensions='jpg,png,jpeg,svg,gif,ico'
                      message='Debe ser un archivo de imagen'
                      messageState={(value: any) => setFileError(value)}
                      loader={setLoader}
                    />
                    <CustomTypography type='alertError'>
                      {fileError}
                    </CustomTypography>
                  </Grid>
                  {logo.blob !== '' && (
                    <Grid
                      className={classes.file}
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                    >
                      <CustomTypography type='definition'>
                        {logo.name}
                      </CustomTypography>
                      <IconButton
                        disabled={!isEdit}
                        onClick={() => deleteFile('logo')}
                      >
                        <HighlightOffRoundedIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <CustomTypography type='subtitle'>{'Colores'}</CustomTypography>
                <Grid container spacing={2}>
                  <Grid container item lg={8} alignItems='center'>
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <CustomTypography type='definition'>
                        {'Color principal'}
                      </CustomTypography>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <ColorPicker
                        colorCode={principalColor}
                        setColorCode={setPrincipalColor}
                        disabled={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item lg={8} alignItems='center'>
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <CustomTypography type='definition'>
                        {'Color secundario'}
                      </CustomTypography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <ColorPicker
                        colorCode={secondaryColor}
                        setColorCode={setSecondaryColor}
                        disabled={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item lg={8} alignItems='center'>
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <CustomTypography type='definition'>
                        {'Botón'}
                      </CustomTypography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <ColorPicker
                        colorCode={buttonColor}
                        setColorCode={setButtonColor}
                        disabled={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item lg={8} alignItems='center'>
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <CustomTypography type='definition'>
                        {'Texto de Botón'}
                      </CustomTypography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <ColorPicker
                        colorCode={buttonTextColor}
                        setColorCode={setButtonTextColor}
                        disabled={!isEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.card}>
                <CustomTypography type='subtitle'>{'Fondo'}</CustomTypography>
                <Grid container spacing={2}>
                  <Grid container item lg={8} alignItems='center'>
                    <Grid item lg={4} md={6} sm={6} xs={5}>
                      <CustomTypography type='definition'>
                        {'Color de fondo'}
                      </CustomTypography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <ColorPicker
                        colorCode={backgroundColor}
                        setColorCode={setBackgroundColor}
                        disabled={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item lg={10} alignItems='center' spacing={2}>
                    <Grid item xs={5} md={3} lg={3}>
                      <CustomTypography type='definition'>
                        {'Imagen de fondo'}
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <SelectFileButton
                        label='Seleccionar'
                        setFile={setBackgroundImage}
                        disabled={!isEdit}
                        acceptedExtensions='jpg,png,jpeg,svg,gif,ico'
                        message='Debe ser un archivo de imagen'
                        messageState={(value: any) => setFileError2(value)}
                        maxFileSize={10}
                        loader={setLoader}
                      />
                      <CustomTypography type='alertError'>
                        {fileError2}
                      </CustomTypography>
                    </Grid>
                    {backgroundImage.blob !== '' && (
                      <Grid className={classes.file} item xs={5} md={6} lg={6}>
                        <CustomTypography type='definition'>
                          {backgroundImage.name}
                        </CustomTypography>
                        <IconButton
                          disabled={!isEdit}
                          onClick={() => deleteFile('backgroundImage')}
                        >
                          <HighlightOffRoundedIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <EditButton
                isEditing={isEdit}
                onClick={() => {
                  setIsEdit(!isEdit)
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <PrimaryButton
                title={isNew ? 'Guardar' : 'Actualizar'}
                props={{ disabled: !isEdit, type: 'submit' }}
                fullWidth={false}
                personalizationColor={{
                  primaryColor: !!color?.buttonColor
                    ? color.buttonColor
                    : !!personalizationColor?.primaryColor
                    ? personalizationColor.primaryColor
                    : '',
                  secondaryColor: personalizationColor?.secondaryColor,
                  buttonColor: personalizationColor?.buttonColor,
                  textButtonColor: !!color?.buttonTextColor
                    ? color.buttonTextColor
                    : !!personalizationColor?.textButtonColor
                    ? personalizationColor.textButtonColor
                    : ''
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Dialog />
        <FullLoader open={loading || loader} viewLoader={true} />
      </form>
    </SnackbarProvider>
  )
}

const useStyles = makeStyles(() => ({
  card: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000033',
    border: '1px solid #B2B2B2',
    borderRadius: '10px',
    opacity: 1,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20
  },

  file: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',

    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '85%',
      whiteSpace: 'nowrap'
    }
  }
}))
