import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FormEventHandler, useEffect, useState } from 'react'
//import { PrimaryButton } from '../../../components/button/PrimaryButton'
import { ControlledSwitch } from '../../../components/Inputs/ControlledSwitch'
import { InputRounded } from '../../../components/Inputs/InputRounded'
import { SelectInputRounded } from '../../../components/Inputs/SelectInputRounded'
import { CompanyProvider } from '../CompanyProvider'
import FullLoader from '../../../components/Loader/FullLoader.component'
import { initialStateCompany } from '../reducers'
import { ISelectStates } from './BasicDataContainer'
import { EditButton } from '../../../components/Button/EditButton'
// import { CustomThemeProvider } from '../../../theme/CustomThemeProvider'
import { PrimaryButton } from '../../../components/Button'
import { CustomTypography } from '../../../components/typography/CustomTypography'
import { ICompanyBasicDataLicence, jsonColor } from '../types'
import { UseFormWatch } from 'react-hook-form'
import { InputRoundedMask } from '../../../components/Inputs'

type Colors = {
  buttonColor?: string
  buttonTextColor?: string
}

type CompanyBasicDataProps = {
  form: any
  formSubmit: Function
  selectStates: ISelectStates
  isNatural: boolean | undefined
  setIsNatural: Function
  loading: boolean
  isEdit?: boolean
  isNew?: boolean
  setIsEdit: Function
  color?: Colors
  personalizationColor?: jsonColor
  watch: UseFormWatch<ICompanyBasicDataLicence>
  handleChangeForm: FormEventHandler<HTMLFormElement>
}

const BasicDataView = ({
  form,
  formSubmit,
  selectStates,
  isNatural,
  setIsNatural,
  isEdit = true,
  isNew,
  loading,
  color,
  personalizationColor,
  watch,
  handleChangeForm
}: CompanyBasicDataProps) => {
  const classes = useStyles()
  const [isEditable, setIsEditable] = useState(isEdit)
  const [stateDocumentType, setStateDocumentType] = useState<number>()
  const initialValues = initialStateCompany.basicData
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = form
  const onError = (errors: any) => {
    console.log('Error: ', errors)
  }

  interface masKTypeDocumentProps {
    documentType: number[]
    mask: string | (string | RegExp)[]
  }
  const masksTypeDocument: masKTypeDocumentProps[] = [
    {
      documentType: [2, 8],
      mask: '999999999999'
    },
    {
      documentType: [4],
      mask: '999999999-9'
    },
    {
      documentType: [6],
      mask: '************'
    }
  ]

  useEffect(() => {
    const legalDocumentTypeId = watch('legalDocumentTypeId')
    if (legalDocumentTypeId) {
      setStateDocumentType(parseInt(legalDocumentTypeId))
    }
  }, [watch('legalDocumentTypeId')])

  const maskDocument = (
    documentType: number | undefined
  ): string | (string | RegExp)[] => {
    let maskString = '9999999999'
    if (documentType) {
      const mask = masksTypeDocument.find((element) =>
        element.documentType.includes(documentType)
      )
      return mask?.mask != undefined ? mask.mask : maskString
    } else {
      return maskString
    }
  }

  return (
    <form
      onChange={handleChangeForm}
      onSubmit={handleSubmit((data: any) => formSubmit(data), onError)}
    >
      <Grid container spacing={2} justify='center'>
        <Grid container direction='row-reverse'>
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={3}
            xs={3}
            style={{ marginTop: 10, marginRight: 10 }}
            justifyContent='flex-end'
          >
            <EditButton
              isEditing={isEditable}
              onClick={() => {
                setIsEditable(!isEditable)
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SelectInputRounded
              defaultValue={initialValues.personTypeId}
              control={control}
              props={{
                helperText: errors.personTypeId?.message,
                error: errors.personTypeId ? true : false,
                disabled: true
              }}
              onChange={(e: any) => {
                if (e.target.value === 1) {
                  setIsNatural(true)
                } else {
                  setIsNatural(false)
                }
              }}
              name='personTypeId'
              label={'Tipo de persona'}
              options={selectStates.personTypes.map((item) => ({
                key: parseInt(item.id, 10),
                value: parseInt(item.id, 10),
                label: item.name
              }))}
            />{' '}
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SelectInputRounded
              defaultValue={initialValues.documentTypeId}
              props={{
                name: 'documentTypeId',
                helperText: errors.documentTypeId?.message,
                error: errors.documentTypeId ? true : false,
                disabled: true
              }}
              control={control}
              name='documentTypeId'
              label={'Tipo de documento'}
              options={selectStates.documentTypes.map((item) => ({
                key: item.id,
                value: item.id,
                label: item.documentTypeDesciption
              }))}
            />{' '}
          </Grid>
          <Grid item lg={3} md={8} sm={8} xs={8}>
            {' '}
            <InputRounded
              defaultValue={initialValues.documentNumber}
              name='documentNumber'
              props={{
                name: 'documentNumber',
                helperText: errors.documentNumber?.message,
                error: errors.documentNumber ? true : false,
                disabled: true
              }}
              control={control}
              label={'Numero de documento'}
              pattern={/^\d+$/}
            />{' '}
          </Grid>
          <Grid
            item
            container
            lg={1}
            md={4}
            sm={4}
            xs={4}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item lg={6} md={6} sm={3} xs={3}>
              <Typography align='center'>{'-'} </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={9} xs={9}>
              <InputRounded
                props={{
                  name: 'dv',
                  helperText: errors.dv?.message,
                  error: errors.dv ? true : false,
                  disabled: true
                }}
                defaultValue={initialValues.dv}
                control={control}
                name='dv'
                label={'Dv'}
              />{' '}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <InputRounded
            props={{
              name: 'legalName',
              helperText: errors.businessName?.message,
              error: errors.businessName ? true : false,
              disabled: !isEditable
            }}
            defaultValue={initialValues.legalName}
            name='legalName'
            control={control}
            label={isNatural ? 'Nombres y apellidos' : 'Razón social'}
            pattern={/^[#.0-9a-zA-Z\s,-/&#]+$/}
          />{' '}
        </Grid>
        <Grid container item spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputRounded
              props={{
                name: 'email',
                helperText: errors.email?.message,
                error: errors.email ? true : false,
                disabled: true
              }}
              name='email'
              control={control}
              defaultValue={initialValues.email}
              label={'Correo Electrónico'}
            />{' '}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputRoundedMask
              defaultValue={initialValues.cellphoneNumber}
              name='cellphoneNumber'
              props={{
                name: 'cellphoneNumber',
                helperText: errors.cellphoneNumber?.message,
                error: errors.cellphoneNumber ? true : false,
                disabled: !isEditable
              }}
              control={control}
              label={'Telefono/Celular'}
              mask={'9999999999'}
            />{' '}
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <CustomTypography type='subtitle'>
            {'Dirección sede principal'}
          </CustomTypography>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item lg={6} md={4} sm={12} xs={12}>
            <InputRounded
              defaultValue={initialValues.fullAddress}
              name='fullAddress'
              props={{
                name: 'fullAddress',
                helperText: errors.fullAddress?.message,
                error: errors.fullAddress ? true : false,
                disabled: !isEditable
              }}
              control={control}
              label={'Dirección'}
              pattern={/^[#.0-9a-zA-Z\s,-/]+$/}
            />{' '}
          </Grid>
          <Grid container item lg={6} md={8} sm={12} xs={12} spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectInputRounded
                defaultValue={initialValues.department}
                props={{
                  name: 'department',
                  helperText: errors.department?.message,
                  error: errors.department ? true : false,
                  disabled: !isEditable
                }}
                control={control}
                name='department'
                label={'Departamento'}
                options={
                  selectStates.departments
                    ? selectStates.departments.map((item) => ({
                        key: item.id,
                        value: item.id,
                        label: item.stateName
                      }))
                    : []
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectInputRounded
                defaultValue={initialValues.city}
                props={{
                  name: 'city',
                  helperText: errors.city?.message,
                  error: errors.city ? true : false,
                  disabled: !isEditable
                }}
                control={control}
                name='city'
                label={'Ciudad'}
                options={
                  selectStates.cities
                    ? selectStates.cities.map((item) => ({
                        key: item.id,
                        value: item.id,
                        label: item.cityName
                      }))
                    : []
                }
              />{' '}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <InputRounded
            defaultValue={initialValues.addressAdditionalInformation}
            label={'Informacion adicional'}
            control={control}
            name='addressAdditionalInformation'
            pattern={/^[#.0-9a-zA-Z\s,-/]+$/}
            props={{
              name: 'addressAdditionalInformation',
              error: errors.additionalInformation ? true : false,
              disabled: !isEditable,
              helperText: errors.additionalInformation?.message
                ? errors.additionalInformation?.message
                : 'Ejemplo: Interior, apartamento, conjunto residencial, barrio, etc',
              FormHelperTextProps: {
                classes: {
                  root: classes.subtitle
                }
              }
            }}
          />{' '}
        </Grid>
        {!isNatural ? (
          <Grid container item spacing={1}>
            {' '}
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
              <CustomTypography type='subtitle'>
                {`Datos representante legal`}
              </CustomTypography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectInputRounded
                  defaultValue={initialValues.legalDocumentTypeId}
                  name='legalDocumentTypeId'
                  props={{
                    helperText: errors.legalDocumentTypeId?.message,
                    error: errors.legalDocumentTypeId ? true : false,
                    disabled: !isEditable
                  }}
                  control={control}
                  label={'Tipo de documento'}
                  options={selectStates.documentTypes.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.documentTypeDesciption
                  }))}
                />{' '}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputRoundedMask
                  defaultValue={initialValues.legalDocumentNumber}
                  props={{
                    name: 'legalDocumentNumber',
                    helperText: errors.documentNumberLr?.message,
                    error: errors.documentNumberLr ? true : false,
                    disabled: !isEditable
                  }}
                  control={control}
                  name='legalDocumentNumber'
                  label={'Numero de documento'}
                  mask={maskDocument(stateDocumentType)}
                />{' '}
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputRounded
                  defaultValue={initialValues.legalFirstName}
                  props={{
                    name: 'legalFirstName',
                    helperText: errors.namesLr?.message,
                    error: errors.namesLr ? true : false,
                    disabled: !isEditable
                  }}
                  control={control}
                  name='legalFirstName'
                  label={'Nombres'}
                  pattern={/^[a-zA-Z\s]*$/}
                />{' '}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputRounded
                  defaultValue={initialValues.legalLastName}
                  props={{
                    name: 'legalLastName',
                    helperText: errors.surnamesLr?.message,
                    error: errors.surnamesLr ? true : false,
                    disabled: !isEditable
                  }}
                  control={control}
                  name='legalLastName'
                  label={'Apellidos'}
                  pattern={/^[a-zA-Z\s]*$/}
                />{' '}
              </Grid>
            </Grid>
          </Grid>
        ) : null}{' '}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction='row'
          spacing={1}
          style={{ display: 'none' }}
        >
          <Grid item lg={1}>
            <Typography>{'Activo'}</Typography>
          </Grid>
          <Grid item lg={1}>
            <ControlledSwitch
              error={errors.idstate ? true : false}
              helperText={errors.idstate?.message}
              // defaultValue={initialValues.idstate}
              name='idstate'
              control={control}
              isDisabled={!isEditable}
              checked={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={2}
          md={3}
          sm={12}
          xs={12}
          justifyContent='center'
          spacing={1}
        >
          {/* Comentario temporal mientras se crea servicio de la funcion de este boton */}
          <PrimaryButton
            title={isNew ? 'Guardar' : 'Actualizar'}
            props={{
              disabled: !isEditable,
              type: 'submit'
            }}
            fullWidth={false}
            personalizationColor={{
              primaryColor: !!color?.buttonColor
                ? color.buttonColor
                : !!personalizationColor?.primaryColor
                ? personalizationColor.primaryColor
                : '',
              secondaryColor: personalizationColor?.secondaryColor,
              buttonColor: personalizationColor?.buttonColor,
              textButtonColor: !!color?.buttonTextColor
                ? color.buttonTextColor
                : !!personalizationColor?.textButtonColor
                ? personalizationColor.textButtonColor
                : ''
            }}
          />
        </Grid>
      </Grid>
      <FullLoader open={loading} viewLoader={true} />
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    color: theme.palette.secondary.main,
    fontSize: '11px/17px',
    letterSpacing: 0
  }
}))

export const CompanyBasicDataView = (props: CompanyBasicDataProps) => {
  return (
    <CompanyProvider>
      <BasicDataView {...props} />
    </CompanyProvider>
  )
}
